import { graphql, useStaticQuery } from "gatsby"
import { Richtext } from "storyblok-js-client"

interface NotFoundStory {
  content: Richtext
}
function useNotFoundPage():NotFoundStory {
  const results = useStaticQuery(graphql`
    query GetNotFoundPage {
      storyblokEntry(field_component: {eq: "NotFoundPage"}) {
        content
        name
        uuid
        slug
      }
    }
  `)

  return JSON.parse(results.storyblokEntry.content)
}

export { NotFoundStory }
export default useNotFoundPage