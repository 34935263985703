import * as React from "react"
import useStoryblok from "../lib/storyblok"
import { Richtext } from "storyblok-js-client"
import { Helmet } from "react-helmet"

import PageTemplate from "../templates/Page"
import { resolveRichText } from "../utils/richText"
import GlobalLayout from "../components/layout/globalLayout/GlobalLayout"
import useNotFoundPage, { NotFoundStory } from "../hooks/useNotFoundPage"

interface ProductionPage {
  content: Richtext
}
interface ProductionPageProps {
  story?: StoryblokRef.Story,
  staticStory?: NotFoundStory
}
function ProductionPage({ story, staticStory }:ProductionPageProps):JSX.Element {
  let text:Richtext | undefined

  const storyData = story ? story.content as ProductionPage : undefined

  if(storyData && storyData.content) {
    text = storyData.content
  } else if (staticStory) {
    text = staticStory.content
  }

  return (
    <GlobalLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page Not Found</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="error_area">
        <div className="container flex">
          <div className="error_container text-center not-found-content" dangerouslySetInnerHTML={resolveRichText(text)} />
        </div>
      </section>
    </GlobalLayout>
  )
}

interface NotFoundDevProps {
  story: any,
  location: any
}
function DevelopmentPage({ story, location }:NotFoundDevProps):JSX.Element {
  const storyData = story.story ? story.story : story
  storyData.seo = {}

  switch(storyData.content.component) {
    case "page":
      return (
        <PageTemplate pageContext={{story: story}} location={location} />
      )
    case "NotFoundPage":
      return (
        <ProductionPage story={story} />
      )
    default:
      console.warn("Unknown Page Type: ", storyData.content.component)
      return (<p>Storyblok has not been configured to render this type of page yet.</p>)
  }
}

interface NotFoundPageProps {
  location: any
}
const NotFoundPage = ({ location }:NotFoundPageProps) => {
  let story = useStoryblok(null, location)
  let staticStory = useNotFoundPage()

  if(story) {
    return <DevelopmentPage story={story} location={location} />
  } else {
    return <ProductionPage staticStory={staticStory} />
  }
}

export default NotFoundPage
